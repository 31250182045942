
export default function Logo() {
  return (
    <h1 style={{fontFamily: "Arial"}}>
      <span style={{color: "#fc2e53"}}>v</span>
      <span style={{color: "#80eacf"}}>e</span>
      <span style={{color: "white"}}>m</span>
      <span style={{color: "#27f9f5"}}>o</span>
    </h1>
  )
}
