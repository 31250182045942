import { createVideoRoomClient, VideoRoomPublisher, VideoRoomSubscriber } from 'janus-simple-videoroom-client'
import React from 'react'

export interface LocalParticipant {
  name: string
  stream: MediaStream
  blurLevel: number
  setBlur(level: number): void
}

export interface RemoteParticipant {
  id: number|string
  name: string
  stream: MediaStream
  muted: boolean
  talk(on: boolean): void
}

type SetLocalParticipant = React.Dispatch<React.SetStateAction<LocalParticipant|null>>
type SetRemoteParticipants = React.Dispatch<React.SetStateAction<RemoteParticipant[]>>

const getSession = lazy(() =>
  createVideoRoomClient({debug: true})
    .then(client => client.createSession("wss://media.diepkhuc.com/jupiter"))
)

let roomLeavePromise = Promise.resolve()


export function joinRoom(roomId: number) {
  const promise = roomLeavePromise.catch(err => "OK").then(() => joinRoomAsync(roomId))
  roomLeavePromise = promise.then(room => room.leavePromise)
  return promise
}

async function joinRoomAsync(roomId: number) {
  const session = await getSession()
  const room = await session.joinRoom(roomId)
  const broadcastData = (text: string) => new Promise((f,r) => (room.pluginHandle as any).data({text, success: f, error: r}))
  let onLeave: () => void
  let myPublisher: {
    id: number|string,
    audioTrack: MediaStreamTrack
  }

  return {
    async publishMyVideo(name: string, blurLevel: number, setLocalParticipant: SetLocalParticipant) {
      const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: {width: 320, height: 180}})
      const audioTrack = stream.getAudioTracks()[0]
      audioTrack.enabled = false
      const videoTrack = stream.getVideoTracks()[0]
      const blurredVideo = await blurVideo(videoTrack, {blurLevel, maxBlurPixels: 20})
      const pub = await room.publish({
        publishOptions: {
          display: name
        },
        mediaOptions: {
          tracks: [
            {type: "data"},
            {type: "audio", capture: audioTrack},
            {type: "video", capture: blurredVideo.track}
          ]
        }
      })
      setLocalParticipant({
        name,
        stream: new MediaStream([blurredVideo.track]),
        blurLevel,
        setBlur(level) {
          blurredVideo.setBlur(level)
          setLocalParticipant(p => p && {...p, blurLevel: level})
        },
      })
      myPublisher = {
        id: pub.publisherId,
        audioTrack
      }
    },

    async subscribeOtherVideos(setRemoteParticipants: SetRemoteParticipants) {
      const subs: {[id: number]: VideoRoomSubscriber} = {}
      room.onPublisherAdded(publishers => publishers.forEach(subscribe))
      room.onPublisherRemoved(unsubscribe)

      async function subscribe(publisher: any) {
        const sub = subs[publisher.id] = await room.subscribe([{feed: publisher.id}], {
          mediaOptions: {
            tracks: [
              {type: "data"}
            ]
          }
        })
        sub.pluginHandle.eventTarget.addEventListener("data", event => {
          const request = JSON.parse(event.detail.data)
          if (request.to === myPublisher.id) {
            if (request.method === "talk") {
              setRemoteParticipants(list => list.map(p => {
                if (p.id === publisher.id) return {...p, muted: !request.on}
                else return p
              }))
            }
            else console.error("Bad request", request)
          }
        })
        setRemoteParticipants(list => [
          ...list,
          {
            id: publisher.id,
            name: publisher.display,
            stream: createStreamFor(sub),
            muted: true,
            talk(on: boolean) {
              myPublisher.audioTrack.enabled = on
              broadcastData(JSON.stringify({to: publisher.id, method: "talk", on}))
                .catch(console.error)
            },
          } as RemoteParticipant
        ])
      }

      async function unsubscribe(publisherId: any) {
        await subs[publisherId].unsubscribe()
        setRemoteParticipants(list => list.filter(p => p.id !== publisherId))
      }
    },

    async leave(setLocalParticipant: SetLocalParticipant, setRemoteParticipants: SetRemoteParticipants) {
      await room.leave()
      setLocalParticipant(null)
      setRemoteParticipants([])
      onLeave()
    },

    leavePromise: new Promise<void>(f => onLeave = f)
  }
}


function createStreamFor(x: VideoRoomPublisher|VideoRoomSubscriber) {
  const stream = new MediaStream()
  x.onTrackAdded(track => stream.addTrack(track))
  x.onTrackRemoved(track => stream.removeTrack(track))
  return stream
}

function lazy<T>(get: () => T) {
  let value: T
  return () => value || (value = get())
}

async function blurVideo(track: MediaStreamTrack, settings: {blurLevel: number, maxBlurPixels: number}) {
  const video = document.createElement("video")
  video.srcObject = new MediaStream([track])
  await video.play()

  const canvas = document.createElement("canvas")
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight

  const ctx = canvas.getContext("2d", {alpha: false})!
  const setBlur = (level: number) => ctx.filter = "blur(" + (settings.maxBlurPixels * level / 100) + "px)"
  setBlur(settings.blurLevel)

  const blurredTrack = canvas.captureStream(0).getTracks()[0] as CanvasCaptureMediaStreamTrack

  const timer = setInterval(() => {
    ctx.drawImage(video, 0, 0)
    blurredTrack.requestFrame()
  }, 40)

  return {
    track: blurredTrack,
    setBlur,
    destroy() {
      clearInterval(timer)
    }
  }
}
