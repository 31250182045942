import React from "react"
import Image from "react-bootstrap/Image"
import Spinner from "react-bootstrap/Spinner"
import Logo from '../components/Logo'
import NotificationBell from "../components/NotificationBell"
import { joinRoom, LocalParticipant, RemoteParticipant } from '../utils/VideoRoom'
import { useSearchParams } from "react-router-dom"


export default function Home() {
  const [searchParams] = useSearchParams()
  const myName = searchParams.get("myName") ?? "Anon"

  const [localParticipant, setLocalParticipant] = React.useState<LocalParticipant|null>(null)
  const [remoteParticipants, setRemoteParticipants] = React.useState<RemoteParticipant[]>([])
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = React.useState<ServiceWorkerRegistration|undefined>()
  const [pushSubscription, setPushSubscription] = React.useState<PushSubscription|null|undefined>()
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    navigator.serviceWorker.register("/sw.js")
    navigator.serviceWorker.ready.then(x => setServiceWorkerRegistration(x))
  }, [])

  React.useEffect(() => {
    if (serviceWorkerRegistration)
      serviceWorkerRegistration.pushManager.getSubscription().then(x => setPushSubscription(x))
  }, [serviceWorkerRegistration])

  React.useEffect(() => {
    if (pushSubscription) {
      setProgress(x => x+1)
      fetch("https://service.lsdsoftware.com/vemo?capabilities=pnSubscribe-1.0", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          method: "pnSubscribe",
          pushSubscription
        })
      })
        .then(res => res.ok || Promise.reject("Server returned " + res.status))
        .then(() => console.log("Sent pushSubscription to server."))
        .catch(console.error)
        .finally(() => setProgress(x => x-1))
    }
  }, [pushSubscription])

  React.useEffect(() => {
    const roomPromise = joinRoom(1234)
    roomPromise
      .then(room => Promise.all([
        room.publishMyVideo(myName, Number(localStorage.getItem("myBlurLevel") || 0), setLocalParticipant),
        room.subscribeOtherVideos(setRemoteParticipants),
        notifyRoomUpdate(`${myName} joins room`, `${window.location.protocol}//${window.location.host}`)
          .catch(console.error)
      ]))
      .catch(console.error)
    return function cleanup() {
      roomPromise.then(room => room.leave(setLocalParticipant, setRemoteParticipants))
    }
  }, [myName])

  function onToggleNotificationBell() {
    if (pushSubscription) onUnsubscribe()
    else onSubscribe()
  }

  function onSubscribe() {
    if (!serviceWorkerRegistration) throw new Error("Missing service worker registration!")
    setProgress(x => x+1)
    Notification.requestPermission()
      .then(status => status === "granted" || Promise.reject("User denied permission"))
      .then(() => serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: "BHYPRZUHUKPUa5d0QD30Kp76eKYufJNgPEb6fXYUN0i5YqZeq3KX5eXH6TCz2glcLGN-gGoziAUJ08lbLzB-FPs"
      }))
      .then(x => setPushSubscription(x))
      .catch(console.error)
      .finally(() => setProgress(x => x-1))
  }

  function onUnsubscribe() {
    if (!pushSubscription) throw new Error("Unexpected")
    setProgress(x => x+1)
    fetch("https://service.lsdsoftware.com/vemo?capabilities=pnUnsubscribe-1.0", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        method: "pnUnsubscribe",
        pushSubscription
      })
    })
      .then(res => res.ok || Promise.reject("Server returned " + res.status))
      .then(() => pushSubscription.unsubscribe())
      .then(() => setPushSubscription(null))
      .then(() => console.log("Removed pushSubscription from server."))
      .catch(console.error)
      .finally(() => setProgress(x => x-1))
  }

  return (
    <main>
      <div style={{display: "flex", alignItems: "center"}}>
        <Logo></Logo>
        <div style={{marginLeft: "1em"}}>
          {pushSubscription !== undefined && !progress &&
            <NotificationBell size='1.5em' state={pushSubscription !== null} onClick={onToggleNotificationBell} />
          }
          {pushSubscription !== undefined && !!progress &&
            <Spinner style={{width: "1.5em", height: "1.5em", borderWidth: ".2em"}} />
          }
        </div>
      </div>
      {localParticipant &&
        <LocalParticipantView participant={localParticipant}
          setBlur={level => {
            localParticipant.setBlur(level)
            localStorage.setItem("myBlurLevel", String(level))
          }} />
      }
      {remoteParticipants.map(participant =>
        <RemoteParticipantView key={participant.id} participant={participant} />
      )}
    </main>
  )
}


function LocalParticipantView(props: {participant: LocalParticipant, setBlur: (level: number) => void}) {
  const {name, stream, blurLevel} = props.participant
  return (
    <div className="participant local">
      <video ref={el => {if (el && el.srcObject !== stream) el.srcObject = stream}} autoPlay muted />
      <div className="name">{name}</div>
      <input className="blurSlider" type="range" min={0} max={100} value={blurLevel}
        onChange={event => props.setBlur(Number(event.target.value))} />
    </div>
  )
}


function RemoteParticipantView(props: {participant: RemoteParticipant}) {
  const {name, stream, muted, talk} = props.participant
  const [isTalking, setIsTalking] = React.useState(false)
  return (
    <div className={"participant remote" + (muted ? "" : " speaking")}>
      <video ref={el => {if (el && el.srcObject !== stream) el.srcObject = stream}} autoPlay muted={muted} />
      <div className="name">{name}</div>
      <Image className="talkButton" src={isTalking ? "/images/microphone-2.png" : "/images/microphone-1.png"} alt="Push to talk"
        onClick={() => setIsTalking(on => {
          talk(!on)
          return !on
        })}
      />
    </div>
  )
}


async function notifyRoomUpdate(message: string, url: string) {
  await fetch("https://service.lsdsoftware.com/vemo?capabilities=pnTestNotify-1.0", {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({
      method: "pnTestNotify",
      message,
      url
    })
  })
}
