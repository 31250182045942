import React, { FormEvent } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { useNavigate } from "react-router-dom"
import Logo from "../components/Logo"


export default function Login() {
  const [myName, setMyName] = React.useState<string>("")
  const navigate = useNavigate()

  React.useEffect(() => {
    setMyName(localStorage.getItem("myName") || "")
  }, [])

  function onSubmit(event: FormEvent) {
    const form = event.target as HTMLFormElement
    const myName = form.myName.value.trim()
    if (myName) {
      localStorage.setItem("myName", myName)
      navigate({
        pathname: "/home",
        search: new URLSearchParams([["myName", myName]]).toString()
      })
    }
    event.preventDefault()
  }

  return (
    <main>
      <Logo></Logo>
      <Form style={{marginTop: "3rem"}} onSubmit={onSubmit}>
        <Form.Control type="text" name="myName" value={myName} placeholder="Enter a name"
          onChange={e => setMyName(e.target.value)} />
        <Button style={{marginTop: "1rem"}} type="submit" variant="primary">Join</Button>
      </Form>
    </main>
  )
}
