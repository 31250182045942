import { Outlet, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Login from "./pages/Login";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />
        <Route path="home" element={<Home />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <Outlet />
  )
}

export default App;
