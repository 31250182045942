import { MouseEventHandler } from "react"
import { MdNotificationsActive, MdNotificationsOff } from "react-icons/md"

export default function NotificationBell(props: {size: string, state: boolean, onClick: MouseEventHandler}) {
  const style = {
    cursor: "pointer"
  }
  return (
    <>
      {props.state === false &&
        <MdNotificationsOff size={props.size} style={style} onClick={props.onClick} />
      }
      {props.state === true &&
        <MdNotificationsActive size={props.size} style={style} onClick={props.onClick} />
      }
    </>
  )
}
